const messages = {
    pt: {
        translations: {
            simpleDict: {
                required: "Obrigatório"
            },
            signup: {
                title: "Cadastre-se",
                toasts: {
                    success: "Usuário criado com sucesso! Faça seu login!!!.",
                    fail: "Erro ao criar usuário. Verifique os dados informados."
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha"
                },
                buttons: {
                    submit: "Cadastrar",
                    login: "Já tem uma conta? Entre!"
                }
            },
            login: {
                title: "Login",
                form: {
                    email: "Email",
                    password: "Senha"
                },
                buttons: {
                    submit: "Entrar",
                    register: "Não tem um conta? Cadastre-se!"
                }
            },
            companies: {
                title: "Cadastrar Empresa",
                form: {
                    name: "Nome da Empresa",
                    plan: "Plano",
                    token: "Token",
                    submit: "Cadastrar",
                    success: "Empresa criada com sucesso!"
                }
            },
            auth: {
                toasts: {
                    success: "Login efetuado com sucesso!"
                },
                token: "Token"
            },
            dashboard: {
                charts: {
                    perDay: {
                        title: "Atendimentos hoje: "
                    }
                }
            },
            connections: {
                title: "Conexões",
                toasts: {
                    deleted: "Conexão com o WhatsApp excluída com sucesso!"
                },
                confirmationModal: {
                    deleteTitle: "Deletar?",
                    deleteMessage: "Você tem certeza? Essa ação não pode ser revertida.",
                    disconnectTitle: "Desconectar",
                    disconnectMessage: "Tem certeza? Você precisará ler o QR Code novamente."
                },
                buttons: {
                    add: "Adicionar WhatsApp",
                    disconnect: "desconectar",
                    tryAgain: "Tentar novamente",
                    qrcode: "QR CODE",
                    newQr: "Novo QR CODE",
                    connecting: "Conectando"
                },
                toolTips: {
                    disconnected: {
                        title: "Falha ao iniciar sessão do WhatsApp",
                        content: "Certifique-se de que seu celular esteja conectado à internet e tente novamente, ou solicite um novo QR Code"
                    },
                    qrcode: {
                        title: "Esperando leitura do QR Code",
                        content: "Clique no botão 'QR CODE' e leia o QR Code com o seu celular para iniciar a sessão"
                    },
                    connected: {
                        title: "Conexão estabelecida!"
                    },
                    timeout: {
                        title: "A conexão com o celular foi perdida",
                        content:
                            "Certifique-se de que seu celular esteja conectado à internet e o WhatsApp esteja aberto, ou clique no botão 'Desconectar' para obter um novo QR Code"
                    },
                    defaultQueue: {
                        warning: "Aviso! Apenas uma fila pode ser padrão para essa conexão"
                    }
                },
                table: {
                    name: "Nome",
                    status: "Status",
                    lastUpdate: "Última atualização",
                    default: "Padrão",
                    actions: "Ações",
                    session: "Sessão"
                }
            },
            whatsappModal: {
                title: {
                    add: "Adicionar WhatsApp",
                    edit: "Editar WhatsApp"
                },
                form: {
                    name: "Nome",
                    default: "Padrão"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                },
                success: "WhatsApp salvo com sucesso."
            },
            qrCode: {
                message: "Leia o QrCode para iniciar a sessão"
            },
            contacts: {
                title: "Contatos",
                toasts: {
                    deleted: "Contato excluído com sucesso!"
                },
                searchPlaceholder: "Pesquisar...",
                confirmationModal: {
                    deleteTitle: "Deletar ",
                    importTitlte: "Importar contatos",
                    importFromPhoneTitle: "Importar contatos",
                    ticketVerificationNoticeTitle: "Chamado aberto!",
                    ticketVerificationNoticeUser: "Contato já possui um chamado em aberto. Por favor, selecione outro contato.",
                    ticketVerificationNoticeAdmin: "Contato já possui um chamado aberto. Gostaria de visualizar?",
                    deleteMessage: "Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.",
                    importMessage: "Deseja importar todos os contatos do arquivo CSV?",
                    importFromPhoneMessage: "Deseja importar todos os contatos do celular?"
                },
                buttons: {
                    import: "Importar do celular",
                    add: "Adicionar Contato",
                    delete: "Apagar Selecionados"
                },
                table: {
                    name: "Nome",
                    whatsapp: "WhatsApp",
                    email: "Email",
                    actions: "Ações"
                }
            },
            contactModal: {
                title: {
                    add: "Adicionar contato",
                    edit: "Editar contato"
                },
                form: {
                    mainInfo: "Dados do contato",
                    extraInfo: "Informações adicionais",
                    name: "Nome",
                    number: "Número do Whatsapp",
                    country: "País",
                    internationalCode: "DDD",
                    email: "E-mail",
                    extraName: "Nome do campo",
                    extraValue: "Valor"
                },
                buttons: {
                    addExtraInfo: "Adicionar informação",
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                },
                success: "Contato salvo com sucesso."
            },
            contactList: {
                dialog: "Lista salva com sucesso."
            },
            queueModal: {
                title: {
                    add: "Adicionar fila",
                    edit: "Editar fila"
                },
                form: {
                    name: "Nome",
                    color: "Cor",
                    greetingMessage: "Mensagem de saudação",
                    complationMessage: "Mensagem de conclusão",
                    outOfHoursMessage: "Mensagem de fora de expediente",
                    ratingMessage: "Mensagem de avaliação",
                    token: "Token"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                }
            },
            userModal: {
                title: {
                    add: "Adicionar usuário",
                    edit: "Editar usuário"
                },
                form: {
                    name: "Nome",
                    email: "Email",
                    password: "Senha",
                    profile: "Perfil",
                    campaignEnable: "Habilitar Campanhas?",
                    maxCampaigns: "Max Campanhas",
                    maxContactsPerCampaign: "Max contatos/campanha"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                },
                success: "Usuário salvo com sucesso."
            },
            form: {
                errors: {
                    number: "Precisa ser um número!"
                }
            },
            scheduleModal: {
                title: {
                    add: "Novo Agendamento",
                    edit: "Editar Agendamento"
                },
                form: {
                    body: "Mensagem *",
                    contact: "Contato",
                    sendAt: "Data de Agendamento",
                    sentAt: "Data de Envio"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                },
                success: "Agendamento salvo com sucesso."
            },
            tagModal: {
                title: {
                    add: "Nova Tag",
                    edit: "Editar Tag"
                },
                form: {
                    name: "Nome",
                    color: "Cor"
                },
                buttons: {
                    okAdd: "Adicionar",
                    okEdit: "Salvar",
                    cancel: "Cancelar"
                },
                success: "Tag salvo com sucesso."
            },
            chat: {
                noTicketMessage: "Selecione um ticket para começar a conversar."
            },
            uploads: {
                titles: {
                    titleUploadMsgDragDrop: "ARRASTE E SOLTE ARQUIVOS NO CAMPO ABAIXO",
                    titleFileList: "Lista de arquivo(s)"
                }
            },
            ticketsManager: {
                buttons: {
                    newTicket: "Novo"
                }
            },
            ticketsQueueSelect: {
                placeholder: "Filas"
            },
            tickets: {
                toasts: {
                    deleted: "O atendimento que você estava foi deletado."
                },
                notification: {
                    message: "Mensagem de"
                },
                tabs: {
                    open: { title: "Abertas" },
                    closed: { title: "Resolvidos" },
                    search: { title: "Busca" }
                },
                search: {
                    placeholder: "Buscar atendimento e mensagens",
                    searchContacts: "Digite um contato ou telefone para pesquisar"
                },
                buttons: {
                    showAll: "Todos"
                }
            },
            transferTicketModal: {
                title: "Transferir Ticket",
                fieldLabel: "Digite para buscar usuários",
                fieldQueueLabel: "Transferir para fila *",
                fieldQueuePlaceholder: "Selecione uma fila",
                noOptions: "Nenhum usuário encontrado com esse nome",
                buttons: {
                    ok: "Transferir",
                    annotate: "comentar",
                    cancel: "Cancelar"
                }
            },
            CommentTicketModaL: {
                titleComment: "Adicione ao ticket um comentário",
                commentUser: "Adicionar comentário",
                commentToBeTyped: "Comentario * :"
            },
            reopenTicketModal: {
                title: "Reabrir Ticket",
                fieldLabel: "Digite para buscar usuários",
                fieldQueueLabel: "Transferir para fila",
                fieldQueuePlaceholder: "Selecione uma fila",
                noOptions: "Nenhum usuário encontrado com esse nome",
                buttons: {
                    ok: "Reabrir",
                    cancel: "Cancelar"
                }
            },
            ticketsList: {
                currentDay: "Hoje",
                pendingHeader: "Fila",
                assignedHeader: "Chats",
                campaignHeader: "Campanha",
                noTicketsTitle: "Nada aqui!",
                noTicketsMessage: "Nenhum atendimento encontrado com esse status ou termo pesquisado",
                buttons: {
                    accept: "Aceitar"
                }
            },
            newTicketModal: {
                title: "Criar Ticket",
                fieldLabel: "Digite para pesquisar o contato",
                add: "Adicionar",
                buttons: {
                    ok: "Salvar",
                    cancel: "Cancelar"
                }
            },
            mainDrawer: {
                listItems: {
                    dashboard: "Dashboard",
                    connections: "Conexões",
                    tickets: "Conversas",
                    quickMessages: "Respostas Rápidas",
                    contacts: "Contatos",
                    queues: "Filas & Chatbot",
                    tags: "Tags",
                    administration: "Administração",
                    users: "Usuários",
                    settings: "Configurações",
                    helps: "Ajuda",
                    messagesAPI: "API",
                    schedules: "Agendamentos",
                    campaigns: "Campanhas",
                    annoucements: "Informativos",
                    chats: "Chat Interno",
                    financeiro: "Financeiro",
                    campaignsListItem: {
                        list: "Listagem",
                        listOfContacts: "Listas de Contatos",
                        campaignConfig: "Configurações"
                    }
                },
                appBar: {
                    user: {
                        profile: "Perfil",
                        logout: "Sair"
                    }
                }
            },
            messagesAPI: {
                title: "API",
                textMessage: {
                    number: "Número",
                    body: "Mensagem",
                    token: "Token cadastrado"
                },
                mediaMessage: {
                    number: "Número",
                    body: "Nome do arquivo",
                    media: "Arquivo",
                    token: "Token cadastrado"
                }
            },
            notifications: {
                noTickets: "Nenhuma notificação."
            },
            quickMessages: {
                title: "Respostas Rápidas",
                buttons: {
                    add: "Nova Resposta"
                },
                dialog: {
                    shortcode: "Atalho",
                    message: "Resposta"
                }
            },
            contactLists: {
                title: "Listas de Contatos",
                table: {
                    name: "Nome",
                    contacts: "Contatos",
                    actions: "Ações"
                },
                buttons: {
                    add: "Nova Lista"
                },
                dialog: {
                    name: "Nome",
                    company: "Empresa",
                    okEdit: "Editar",
                    okAdd: "Adicionar",
                    add: "Adicionar",
                    edit: "Editar",
                    cancel: "Cancelar"
                },
                confirmationModal: {
                    deleteTitle: "Excluir?",
                    deleteMessage: "Esta ação não pode ser revertida."
                },
                toasts: {
                    deleted: "Registro excluído"
                }
            },
            contactListItems: {
                title: "Contatos",
                searchPlaceholder: "Pesquisa",
                buttons: {
                    add: "Novo",
                    lists: "Listas",
                    import: "Importar"
                },
                dialog: {
                    name: "Nome",
                    number: "Número",
                    whatsapp: "Whatsapp",
                    email: "E-mail",
                    okEdit: "Editar",
                    okAdd: "Adicionar",
                    add: "Adicionar",
                    edit: "Editar",
                    cancel: "Cancelar"
                },
                table: {
                    name: "Nome",
                    number: "Número",
                    whatsapp: "Whatsapp",
                    email: "E-mail",
                    actions: "Ações"
                },
                confirmationModal: {
                    deleteTitle: "Excluir?",
                    deleteMessage: "Esta ação não pode ser revertida.",
                    importMessage: "Deseja importar os contatos desta planilha? ",
                    importTitlte: "Importar"
                },
                toasts: {
                    deleted: "Registro excluído"
                }
            },
            campaigns: {
                title: "Campanhas",
                searchPlaceholder: "Pesquisa",
                buttons: {
                    add: "Nova Campanha",
                    contactLists: "Listas de Contatos"
                },
                table: {
                    name: "Nome",
                    whatsapp: "Conexão",
                    contactList: "Contatos",
                    status: "Status",
                    scheduledAt: "Agendamento",
                    completedAt: "Concluída",
                    confirmation: "Confirmação",
                    actions: "Ações"
                },
                dialog: {
                    new: "Nova Campanha de {{name}}",
                    update: "Editar Campanha de {{name}}",
                    readonly: "Campanha Apenas Visualização de {{name}}",
                    form: {
                        queue: "Fila",
                        name: "Nome",
                        maxCampaigns: "Max Campanhas",
                        maxContactsPerCampaign: "Max contatos/campanha",
                        message1: "Mensagem 1",
                        message2: "Mensagem 2",
                        message3: "Mensagem 3",
                        message4: "Mensagem 4",
                        message5: "Mensagem 5",
                        confirmationMessage1: "Mensagem de Confirmação 1",
                        confirmationMessage2: "Mensagem de Confirmação 2",
                        confirmationMessage3: "Mensagem de Confirmação 3",
                        confirmationMessage4: "Mensagem de Confirmação 4",
                        confirmationMessage5: "Mensagem de Confirmação 5",
                        messagePlaceholder: "Conteúdo da mensagem",
                        whatsapp: "Conexão",
                        status: "Status",
                        scheduledAt: "Agendamento",
                        confirmation: "Confirmação",
                        contactList: "Lista de Contato"
                    },
                    buttons: {
                        add: "Adicionar",
                        edit: "Atualizar",
                        okadd: "Ok",
                        cancel: "Cancelar Disparos",
                        restart: "Reiniciar Disparos",
                        close: "Fechar",
                        attach: "Anexar Arquivo"
                    }
                },
                confirmationModal: {
                    deleteTitle: "Excluir?",
                    deleteMessage: "Esta ação não pode ser revertida."
                },
                toasts: {
                    success: "Operação realizada com sucesso",
                    cancel: "Campanha cancelada",
                    restart: "Campanha reiniciada",
                    deleted: "Registro excluído"
                }
            },
            announcements: {
                title: "Informativos",
                searchPlaceholder: "Pesquisa",
                buttons: {
                    add: "Novo Informativo",
                    contactLists: "Listas de Informativos"
                },
                table: {
                    priority: "Prioridade",
                    title: "Title",
                    text: "Texto",
                    mediaName: "Arquivo",
                    status: "Status",
                    actions: "Ações"
                },
                dialog: {
                    edit: "Edição de Informativo",
                    add: "Novo Informativo",
                    update: "Editar Informativo",
                    readonly: "Apenas Visualização",
                    form: {
                        priority: "Prioridade",
                        title: "Title",
                        text: "Texto",
                        mediaPath: "Arquivo",
                        status: "Status"
                    },
                    buttons: {
                        add: "Adicionar",
                        edit: "Atualizar",
                        okadd: "Ok",
                        cancel: "Cancelar",
                        close: "Fechar",
                        attach: "Anexar Arquivo"
                    }
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage: "Esta ação não pode ser revertida."
                },
                toasts: {
                    success: "Operação realizada com sucesso",
                    deleted: "Registro excluído"
                }
            },
            campaignsConfig: {
                title: "Configurações de Campanhas"
            },
            queues: {
                title: "Filas & Chatbot",
                table: {
                    name: "Nome",
                    color: "Cor",
                    greeting: "Mensagem de saudação",
                    actions: "Ações"
                },
                buttons: {
                    add: "Adicionar fila"
                },
                confirmationModal: {
                    deleteTitle: "Excluir",
                    deleteMessage:
                        "Você tem certeza? Essa ação não pode ser revertida! Os atendimentos dessa fila continuarão existindo, mas não terão mais nenhuma fila atribuída."
                }
            },
            queueSelect: {
                inputLabel: "Filas",
                inputLabelDefault: "Fila Padrão"
            },
            whatsappSelect: {
                inputLabel: "Conexões"
            },
            users: {
                title: "Usuários",
                table: {
                    name: "Nome",
                    email: "Email",
                    profile: "Perfil",
                    actions: "Ações"
                },
                buttons: {
                    add: "Adicionar usuário"
                },
                toasts: {
                    deleted: "Usuário excluído com sucesso."
                },
                confirmationModal: {
                    deleteTitle: "Excluir?",
                    deleteMessage: "Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para a fila."
                }
            },
            helps: {
                title: "Central de Ajuda"
            },
            schedules: {
                title: "Agendamentos",
                confirmationModal: {
                    deleteTitle: "Você tem certeza que quer excluir este Agendamento?",
                    deleteMessage: "Esta ação não pode ser revertida."
                },
                table: {
                    contact: "Contato",
                    user: "Criado por",
                    assignedUser: "Atribuido a",
                    queue: "Fila",
                    body: "Mensagem",
                    sendAt: "Data de Agendamento",
                    sentAt: "Data de Envio",
                    status: "Status",
                    actions: "Ações"
                },
                buttons: {
                    add: "Novo Agendamento"
                },
                toasts: {
                    deleted: "Agendamento excluído com sucesso."
                }
            },
            tags: {
                title: "Tags",
                confirmationModal: {
                    deleteTitle: "Você tem certeza que quer excluir esta Tag?",
                    deleteMessage: "Esta ação não pode ser revertida."
                },
                table: {
                    name: "Nome",
                    color: "Cor",
                    tickets: "Registros Tagdos",
                    actions: "Ações"
                },
                buttons: {
                    add: "Nova Tag"
                },
                toasts: {
                    deleted: "Tag excluído com sucesso."
                }
            },
            settings: {
                success: "Configurações salvas com sucesso.",
                title: "Configurações",
                settings: {
                    userCreation: {
                        name: "Criação de usuário",
                        options: {
                            enabled: "Ativado",
                            disabled: "Desativado"
                        }
                    }
                }
            },
            messagesList: {
                header: {
                    assignedTo: "Atribuído à:",
                    buttons: {
                        returnToQueue: "Retornar para a Fila",
                        return: "Retornar",
                        resolve: "Resolver",
                        reopen: "Reabrir",
                        accept: "Aceitar"
                    }
                }
            },
            messagesInput: {
                placeholderOpen: "Digite uma mensagem",
                placeholderClosed: "Reabra ou aceite esse ticket para enviar uma mensagem.",
                signMessage: "Assinar"
            },
            contactDrawer: {
                header: "Dados do contato",
                buttons: {
                    edit: "Editar contato"
                },
                extraInfo: "Outras informações"
            },
            ticketOptionsMenu: {
                schedule: "Agendamento",
                delete: "Deletar",
                transfer: "Transferir",
                comment: "Comentar",
                registerAppointment: "Observações do Contato",
                appointmentsModal: {
                    title: "Observações do Contato",
                    textarea: "Observação",
                    placeholder: "Insira aqui a informação que deseja registrar"
                },
                confirmationModal: {
                    title: "Deletar o ticket ",
                    titleFrom: "do contato ",
                    message: "Atenção! Todas as mensagens relacionadas ao ticket serão perdidas."
                },
                buttons: {
                    delete: "Excluir",
                    cancel: "Cancelar"
                }
            },
            confirmationModal: {
                buttons: {
                    affirmation: "Sim",
                    negation: "Não",
                    confirm: "Confirmar",
                    cancel: "Fechar"
                }
            },
            messageOptionsMenu: {
                delete: "Deletar",
                reply: "Responder",
                confirmationModal: {
                    title: "Apagar mensagem?",
                    message: "Esta ação não pode ser revertida."
                }
            },
            backendErrors: {
                ERR_NO_OTHER_WHATSAPP: "Deve haver pelo menos um WhatsApp padrão.",
                ERR_NO_DEF_WAPP_FOUND: "Nenhum WhatsApp padrão encontrado. Verifique a página de conexões.",
                ERR_WAPP_NOT_INITIALIZED: "Esta sessão do WhatsApp não foi inicializada. Verifique a página de conexões.",
                ERR_WAPP_CHECK_CONTACT: "Não foi possível verificar o contato do WhatsApp. Verifique a página de conexões",
                ERR_WAPP_INVALID_CONTACT: "Este não é um número de Whatsapp válido.",
                ERR_WAPP_DOWNLOAD_MEDIA: "Não foi possível baixar mídia do WhatsApp. Verifique a página de conexões.",
                ERR_INVALID_CREDENTIALS: "Erro de autenticação. Por favor, tente novamente.",
                ERR_SENDING_WAPP_MSG: "Erro ao enviar mensagem do WhatsApp. Verifique a página de conexões.",
                ERR_DELETE_WAPP_MSG: "Não foi possível excluir a mensagem do WhatsApp.",
                ERR_OTHER_OPEN_TICKET: "Já existe um tíquete aberto para este contato.",
                ERR_SESSION_EXPIRED: "Sessão expirada. Por favor entre.",
                ERR_USER_CREATION_DISABLED: "A criação do usuário foi desabilitada pelo administrador.",
                ERR_CAMPAIGN_CREATION_MAX_LIMIT: "Você atingiu o limite máximo de criação de campanhas.",
                ERR_CAMPAIGN_CREATION_MAX_LIMIT_CONTACTS: "Você atingiu o limite máximo de contatos criação da campanha.",
                ERR_NO_PERMISSION: "Você não tem permissão para acessar este recurso.",
                ERR_DUPLICATED_CONTACT: "Já existe um contato com este número.",
                ERR_NO_SETTING_FOUND: "Nenhuma configuração encontrada com este ID.",
                ERR_NO_CONTACT_FOUND: "Nenhum contato encontrado com este ID.",
                ERR_NO_TICKET_FOUND: "Nenhum tíquete encontrado com este ID.",
                ERR_NO_USER_FOUND: "Nenhum usuário encontrado com este ID.",
                ERR_NO_WAPP_FOUND: "Nenhum WhatsApp encontrado com este ID.",
                ERR_CREATING_MESSAGE: "Erro ao criar mensagem no banco de dados.",
                ERR_CREATING_TICKET: "Erro ao criar tíquete no banco de dados.",
                ERR_FETCH_WAPP_MSG: "Erro ao buscar a mensagem no WhtasApp, talvez ela seja muito antiga.",
                ERR_QUEUE_COLOR_ALREADY_EXISTS: "Esta cor já está em uso, escolha outra.",
                ERR_WAPP_GREETING_REQUIRED: "A mensagem de saudação é obrigatório quando há mais de uma fila.",
                ERR_CAMPAIGN_REQUIRED: "Faltam dados obrigatórios a serem preenchidos na campanha.",
                ERROR_VERIFYING_NUMBER_ON_WHATSAPP: "Erro ao verificar número no whatsapp.",
                MISSING_NUMBER_FOR_CONTACT: "Número ausente no arquivo csv para o contato"
            }
        }
    }
};

export { messages };
