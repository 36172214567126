import * as Yup from "yup";
import api from "../../services/api";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { Formik, Form, Field } from "formik";
import Button from "@material-ui/core/Button";
import nameMask from "../ContactModal/nameMask";
import toastError from "../../errors/toastError";
import { green } from "@material-ui/core/colors";
import React, { useState, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import telephoneNumberMasck from "../ContactModal/telephoneNumberMasck";
import ContactSchemaValidation from "../ContactModal/contactFieldsValidation";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexWrap: "wrap"
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },

    btnWrapper: {
        position: "relative"
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12
    },

    textCenter: {
        backgroundColor: "red"
    }
}));

export function ContactForm({ initialContact, onSave, onCancel }) {
    const classes = useStyles();
    const [contact, setContact] = useState(initialContact);

    useEffect(() => {
        initialContact.number = telephoneNumberMasck(initialContact.number);
        setContact(initialContact);
    }, [initialContact]);

    const handleSaveContact = async (values) => {
        values.number = "+55 " + values.number;
        try {
            values.name = nameMask(values.name);
            if (contact.id) {
                await api.put(`/contacts/${contact.id}`, values);
            } else {
                const { data } = await api.post("/contacts", values);
                if (onSave) {
                    onSave(data);
                }
            }
            toast.success(i18n.t("contactModal.success"));
        } catch (err) {
            toastError(err);
        }
    };

    return (
        <Formik
            initialValues={contact}
            enableReinitialize={true}
            validationSchema={ContactSchemaValidation}
            onSubmit={(values, actions) => {
                setTimeout(() => {
                    handleSaveContact(values);
                    actions.setSubmitting(false);
                }, 400);
            }}>
            {({ values, errors, touched, isSubmitting, handleChange }) => (
                <Form>
                    <Grid container spacing={1}>
                        {/* <Grid item xs={12}>
                            <Typography variant="subtitle1" gutterBottom>
                                {i18n.t("contactModal.form.mainInfo")}
                            </Typography>
                        </Grid> */}
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.name")}
                                name="name"
                                autoFocus
                                error={touched.name && Boolean(errors.name)}
                                helperText={touched.name && errors.name}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                variant="outlined"
                                margin="dense"
                                className={classes.textField}
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.number")}
                                name="number"
                                error={touched.number && Boolean(errors.number)}
                                helperText={touched.number && errors.number}
                                onChange={(e) => {
                                    e.target.value = telephoneNumberMasck(e.target.value);
                                    handleChange(e);
                                }}
                                placeholder="(XX) XXXXX-XXXX"
                                variant="outlined"
                                margin="dense"
                                fullWidth
                                required
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Field
                                as={TextField}
                                label={i18n.t("contactModal.form.email")}
                                name="email"
                                error={touched.email && Boolean(errors.email)}
                                helperText={touched.email && errors.email}
                                placeholder="Email address"
                                fullWidth
                                margin="dense"
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12} spacing={1}>
                            <Grid container spacing={1}>
                                <Grid xs={6} item>
                                    <Button onClick={onCancel} color="secondary" disabled={isSubmitting} variant="outlined" fullWidth>
                                        {i18n.t("contactModal.buttons.cancel")}
                                    </Button>
                                </Grid>
                                <Grid classes={classes.textCenter} xs={6} item>
                                    <Button
                                        type="submit"
                                        style={{ color: "#FFFFFF" }}
                                        color="primary"
                                        disabled={isSubmitting}
                                        variant="contained"
                                        className={classes.btnWrapper}
                                        fullWidth>
                                        {contact.id ? `${i18n.t("contactModal.buttons.okEdit")}` : `${i18n.t("contactModal.buttons.okAdd")}`}
                                        {isSubmitting && <CircularProgress size={24} className={classes.buttonProgress} />}
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
}
