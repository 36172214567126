const telephoneNumberMasck = (number) => {
    let telephonew = number;

    // Remove todos os caracteres que não são números
    telephonew = telephonew.replace(/\D/g, "");

    // Verifica se o número começa com "55" e tem mais de 10 dígitos (indicando que é DDI)
    if (telephonew.startsWith("55") && telephonew.length > 10) {
        // Remove o DDI "55", mantendo apenas o número com DDD
        telephonew = telephonew.replace(/^55/, "");
    }

    // Aplica a formatação (XX) XXXX-XXXX ou (XX) XXXXX-XXXX para números móveis
    if (telephonew.length > 10) {
        telephonew = telephonew.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3");
    } else {
        telephonew = telephonew.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
    }

    return telephonew;
};

export default telephoneNumberMasck;
