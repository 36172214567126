import * as Yup from "yup";

const ContactSchemaValidation = Yup.object().shape({
    name: Yup.string().min(4, "Nome muito curto! Necessário no mínimo 4 caracteres.").required("Preenchimento obrigátorio!"),
    email: Yup.string().email("E-mail inválido! Formato sugerido: usuario@seudominio.com.br"),
    number: Yup.string().min(14, "Quantidade de caracteres insuficiente! Necessário no mínimo 14 caracteres.").required("Preenchimento obrigátorio!"),
    number: Yup.string()
        .max(15, "Quantidade de caracteres acima do Limite! Necessário no máximo 15 caracteres.")
        .required("Preenchimento obrigátorio!")
});

export default ContactSchemaValidation;
